<template>
  <div class="loader-container" v-if="isLoading">
    <span class="loader"></span>
  </div>
  <div class="pie-graphic" v-else>
    <apexchart
      class="pie-graphic-chart"
      type="pie"
      :options="paymentMethodsChartOptions.options"
      :series="paymentMethodsChartOptions.series"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { getPaymentMethod } from "@/mixins";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";

const isLoading = ref(true);

const managerDashboardService = new ManagerDashboardController(
  props.managerId,
  props.periodStart,
  props.periodEnd,
  props.gasStationId
);

const props = defineProps({
  managerId: {
    type: Number,
    required: true,
  },
  periodStart: {
    type: String,
    required: true,
  },
  periodEnd: {
    type: String,
    required: true,
  },
  gasStationId: {
    type: Number,
    required: true,
  },
});

const chartData = ref([]);

const paymentMethodsChartOptions = ref({
  series: [],
  options: {
    colors: ["#FFC145", "#007E60", "#7DED84", "#E8EFEB", "#11592E"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        customScale: 0.65,
        offsetX: -100,
        offsetY: -70,
      },
      dataLabels: {
        offset: -10,
      },
    },
    dataLabels: {
      enabled: true,
      offset: -10,
      colors: ["#000"],
    },
    title: {
      text: "Formas de Pagamento",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#11592E",
      },
    },
    labels: [],
    legend: {
      offsetX: 20,
      offsetY: 75,
      fontSize: 16,

      markers: {
        width: 30,
        height: 30,
        radius: 50,
        offsetY: 10,
        offsetX: -10,
      },

      itemMargin: {
        vertical: 5,
      },
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          legend: {
            position: "bottom",
            fontSize: "12px",
            offsetY: 0,
            offsetX: 0,
            markers: {
              width: 15,
              height: 15,
              horizontalAlign: "center",
              offsetX: 0,
              offsetY: 0,
            },
          },
          chart: {
            offsetX: 0,
            offsetY: 0,
            height: 480,
          },
          plotOptions: {
            pie: {
              offsetX: 0,
              offsetY: -50,
            },
            dataLabels: {
              offset: -10,
            },
          },
        },
      },
    ],
  },
  set setLabels(data) {
    this.options.labels = data;
  },
  set setSeries(data) {
    this.series = data;
  },
});

const updatePaymentMethodsChart = () => {
  const series = chartData.value.map((data) => {
    return data.percent;
  });

  const labels = chartData.value.map((data) => {
    return getPaymentMethod(data.paymentMethod);
  });

  paymentMethodsChartOptions.value.setSeries = series;
  paymentMethodsChartOptions.value.setLabels = labels;
};

onMounted(() => {
  getData();
});

const getData = async () => {
  chartData.value = await managerDashboardService.paymenthMethodsData();
  updatePaymentMethodsChart();
  isLoading.value = false;
};

watch(props, (newVal, oldVal) => {
  if (newVal.toString() != oldVal.toString()) {
    getData();
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.pie-graphic {
  @include device(mobile) {
    position: relative;
    top: 10px;
    right: 15px;
    width: 310px;
    height: 380px;
  }
}

.loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #11592e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
